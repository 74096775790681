.about-wrapper {
    padding: 3vw 13vw;
}

.about-wrapper h1 {
    text-align: center;
    margin: 0 0 4vw;
}

.about-content {
    display: flex;
}

.about-content img {
    height: 100%;
}

.about-content p {
    padding: 20px 5%;
    line-height: 150%;
    /* background-color: red; */
}

.about-content p a {
    font-weight: bold;
    color: inherit;
    transition: all 0.3s;
}

.about-content p a:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 800px) {
    .about-content {
        flex-direction: column;
    }
}