@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');

* {
    font-family: 'Roboto';
    margin: 0;
}

html {
    background-color: #f8f7f1;
    color: #4a4a4a
}

.body-div {
    height: 100vh;
    position: relative;
}

/* For the home display page */

.gemstones-home-page {
    text-align: center;
}

.gemstones-home-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 50px 10vw;
}

.gemstones-display a {
    text-align: center;
    width: 30%;
    margin: 30px 10px;
    text-decoration: none;
}

.gemstones-display a p {
    text-align: left;
    margin-left: 35px;
    padding-left: 2px;
    color: black;
    text-transform: uppercase;
    letter-spacing: 3px;
    transition: all ease-in-out 0.3s;
}
.gemstones-display a:hover p {
    padding-left: 10px;
}

/* For the individual gemstone page */

.gemstone-section {
    display: flex;
    width: 60vw;
    margin: 40px 0;
}
.gemstone-section .showcase {
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}
.gemstone-section .minting-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0px 30px 30px;
}

.gemstone-section .minting-section p {
    text-align: left;
}

.gemstone-img {
    max-width: 40vw;
    max-height: 35vh;
}

.content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    text-align: center;
}

.title {
    font-size: 24px;
}

/* FOOTER STYLES */

.footer {
    background-color: black;
    height: 25vh;
    /* width: calc(100vw - 70px); */
    /* position: absolute; */
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
}
.footer div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 80%;
}
.footer div a { 
    color: #f8f7f1;
    font-size: 11px;
    text-transform: uppercase;
    padding-bottom: 1px;
    letter-spacing: 1.5px;
    border-bottom: solid 0.5px #f8f7f1;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.footer div a:hover {
    opacity: 0.8;
    cursor: pointer;
    padding-bottom: 3px;
}
.footer img {
    max-height: 80%;
}

/* make it responsive */
@media only screen and (max-width: 800px) {
    .gemstone-section {
        flex-direction: column;
        align-items: center;
    }
    .gemstone-section div {
        align-items: center;
    }
    .footer {
        position: static;
    }

    .gemstones-display a {
        width: 50%;
        margin: 20px 10px;
    }

    .gemstones-display a p {
        font-size: 12px;
        margin-left: 15px;
    }

    .gemstone-section { 
        width: 80vw;
    }

    .gemstone-section .minting-section {
        width: 50vw;
        padding: 30px;
    }
}