.button {
  background-color: transparent;
  border-radius: 50px;
  padding: 10px 16px;
  border: 2px solid #4a4a4a;
  color: #4a4a4a;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  margin: 20px 0px;
}

.button:hover {
  opacity: 0.6;
  cursor: pointer;
  padding: 10px 24px;
}

.button:disabled,
.button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
