.background-404 {
    position: absolute;
    font-size: 27em;
    z-index: -1;
    opacity: 0.3;
    left: 0;
    top: 80px;
}

.body-404 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}