.menu-button {
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border-radius: 0px;
    border: none;
    color: red;
    height: 48px;
    margin: 0px;
    min-height: 72px;
    min-width: 72px;
    padding: 0px 0px;
    // width: 100%;
    transition: all 0.3s;
    cursor: pointer;
    z-index: 5;
    &:hover, 
    &:focus,
    &:active {
      outline: none;
    }
  }
  
  .menu-button.active {
  
    > .bar {
      background-color: transparent;
      transition: .2s;
    }
    > .bar:before {
      transform: rotate(45deg) translate(4px, 14px);
      transition: .3s;
      width: 44px;
      left: 1px;
    }
    > .bar:after {
      transform: rotate(-45deg) translate(3px, -12px);
      transition: .3s;
      width: 44px;
      left: 1px;
    }
  }

  .menu-button:hover {
    > .bar {
      width: 35px;
      opacity: 0.8;
    }
  }
  
  .bar {
    background-color: #4a4a4a;
    width: 30px;
    height: 2px;
    margin: 4px auto;
    border-radius: 3px;
    display: block;
    position: relative;
    transition: all 0.3s;
    backface-visibility: hidden;
  }
  
  .bar:before,
  .bar:after {
    content: '';
    background-color: #4a4a4a;
    border-radius: 3px;
    width: 35px;
    height: 2px;
    margin: 4px auto;
    position: absolute;
    transition: all 0.3s;
    backface-visibility: hidden;
  }
  
  .bar:before {
    top: -16px;
    left: 0px;
  }
  
  .bar:after {
    bottom: -16px;
    left: 0px;
  }

//   Menu styling

.menu {
    position: fixed;
    background-color: #f8f7f1;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0px;
    z-index: 2;
    transition: 0.3s ease-in-out;
    transform: translateX(120%);
}

.menu.active {
    transform: translateX(0%);
}

.menu-content-wrapper {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
}